<template>
  <div id="category-list">
    <!-- Filter Bar -->
    <FilterBar />

    <!-- Music Category Table -->
    <vuetable ref="vuetable"
              data-path="mcategories"
              pagination-path="pagination"
              :api-url="apiUrl"
              :fields="fields"
              :http-options="httpOptions"
              :css="css.table"
              :per-page="10"
              :append-params="moreParams"
              :query-params="{
                sort: 'sort',
                page: 'page',
                perPage: 'perPage'}"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:loading="showLoader"
              @vuetable:loaded="hideLoader">
      <template class="text-center" slot="images" slot-scope="props">
        <img class="categ-thumbnail"
             :src="props.rowData.music_category_img_url"
             alt="thumbnail"
             width="80"
             height="80"
             @click="itemAction('change-thumbnail', props.rowData,
               props.rowIndex)"
             v-if="props.rowData.music_category_img_url" />
        <img class="categ-thumbnail"
             src="../../../../assets/images/music_category_default.png"
             alt="thumbnail"
             width="80"
             height="80"
             @click="itemAction('change-thumbnail', props.rowData,
               props.rowIndex)"
             v-else />
      </template>

      <!-- Update Order -->
      <template class="text-center" slot="order" slot-scope="props">
        <select class="form-control"
                v-model="props.rowData.order_no"
                @change="changeOrder(props.rowData)">
          <option v-for="(value, key) in orderOptions"
                  :key=" key"
                  :value="value">
            {{ value }}
          </option>
        </select>
      </template>
    </vuetable>

    <!-- Pagination -->
    <div class="d-flex align-items-center justify-content-end">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination ref="pagination"
                           @vuetable-pagination:change-page="onChangePage"
                           :css="css.pagination">
      </vuetable-pagination>
    </div>

    <!-- Loading -->
    <loading :active.sync="loading"
             :is-full-page="true"
             background-color="#000"
             color="#068bcd"
             :opacity="0.3"
             :width="70"
             :height="70">
    </loading>
  </div>
</template>

<script>
  import TableStyle from '../../table-bootstrap-css.js';
  import Vue from 'vue';
  import 'vue-loading-overlay/dist/vue-loading.css';
  
  const CategoryListActions =
    () => import('@/components/admin/category/music/CategoryListActions.vue');
  const FilterBar =
    () => import('@/components/admin/category/music/FilterBar.vue');
  const SimpleUpload = () => import('../../SimpleUpload.vue');

  const Loading = () => import('vue-loading-overlay');
  const Vuetable = () => import('vuetable-2/src/components/Vuetable');
  const VuetablePagination = () =>
  import('vuetable-2/src/components/VuetablePagination');
  const VuetablePaginationInfo = () =>
  import('vuetable-2/src/components/VuetablePaginationInfo');

  Vue.component('mcategory-list-actions', CategoryListActions);

  export default {
    name : 'MusicCategoryList',
    data() {
      return {
        loading  : false,
        maxOrder : 0,
        apiUrl   : process.env.VUE_APP_ROOT_API +
          'api/music/categoriesAdmin',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [
          {
            name       : 'music_category_id',
            title      : 'Category<br>ID',
            sortField  : 'music_category_id',
            direction  : 'desc',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : '__slot:images',
            title      : 'Category Thumbnail',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : 'music_category_title',
            title      : 'Category Name',
            sortField  : 'music_category_title',
            direction  : 'desc',
            titleClass : 'text-center',
          },
          {
            name       : 'subscription_type',
            title      : 'Premium',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : '__slot:order',
            title      : 'Order',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : '__component:mcategory-list-actions',
            title      : 'Actions',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
        ],
      }
    },
    computed : {
      orderOptions() {
        const options = [];
        for(let i = 1; i <= this.maxOrder; i++)
          options.push(i);
        
        return options;
      },
    },
    components : {
      FilterBar,
      // eslint-disable-next-line vue/no-unused-components
      SimpleUpload,
      Loading,
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    methods : {

      /**
       * Table Data
       * @param data
       * @return {object}
       */
      transform(data) {
        this.maxOrder = 0;
        const transformed = {};
        transformed.mcategories = [];

        transformed.pagination = {
          total         : data.result.total,
          per_page      : data.result.perPage,
          current_page  : data.result.currentPage,
          last_page     : data.result.lastPage,
          next_page_url : data.result.nextPageUrl,
          prev_page_url : data.result.prevPageUrl,
          from          : data.result.from,
          to            : data.result.to,
        };
        
        for (let i = 0; i < data.result.data.length; i++) {
          transformed.mcategories.push({
            music_category_id      : data.result.data[i].music_category_id,
            music_category_title   : data.result.data[i].music_category_title,
            music_category_img_url : data.result.data[i].music_category_img_url,
            subscription_type      : (data.result.data[i].subscription_type) ?
              'YES' : 'NO',
            order_no : data.result.data[i].order_no,
            albums   : data.result.data[i].albums,
          });

          if (data.result.data[i].order_no > this.maxOrder)
            this.maxOrder = data.result.data[i].order_no;
        }

        return transformed;
      },

      /**
       * Show Change thumbnail modal
       * @param action
       * @param data
       * @param index
       */
      // eslint-disable-next-line no-unused-vars
      itemAction(action, data, index) {
        if (action === 'change-thumbnail') {
          data.img_url = data.music_category_img_url;
          data.id = data.music_category_id;
          data.image_type = 'mCategory';
          
          this.$modal.show(SimpleUpload, {
            data  : data,
            image : 'music-category-thumbnail',
          }, {
            height : 'auto',
            width  : '30%',
          }, {
            'reload-table' : (event) => {
              this.$refs.vuetable.refresh();
            },
          });
        }
      },

      /**
       * Change category order
       * @param data
       */
      changeOrder(data) {
        this.showLoader();
        this.$http.post('api/music/setCategoryOrderNumber', {
          mCategoryId : data.music_category_id,
          orderNo     : data.order_no,
        }).then(() => {
          this.$refs.vuetable.refresh();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Updated order successfully',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops Something Went Wrong!. Please try again later.',
          });
        }).finally(() => {
          this.hideLoader();
        });
      },

      /**
       * Update Paginaton info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * Update Vuetable page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      /**
       * On Filter Set
       *
       * @param filterText
       */
      onFilterSet(filterText) {
        this.moreParams = {
          'filter' : filterText,
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * On Filter Reset
       */
      onFilterReset() {
        this.moreParams = {};
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * Show Loader
       */
      showLoader() {
        this.loading = true;
      },

      /**
       * hide Loader
       */
      hideLoader() {
        this.loading = false;
      },
    },
    mounted() {
      this.$events.$on('filter-set-category', eventData =>
        this.onFilterSet(eventData));
      this.$events.$on('filter-reset-category', () => this.onFilterReset());
      this.$events.$on('reload', () =>
        Vue.nextTick(() => this.$refs.vuetable.refresh()));
      this.$events.$on('loading-state', eventData => {
        if (eventData)
          this.showLoader();
        else
          this.hideLoader();
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import
    "../../../../assets/scss/components/admin/category/music/category-list";
</style>